import React, { useCallback, useMemo } from 'react';
import FormInput from './FormInput';
import { buildPage } from '../Page';

function addRowSpan(cols, rowSpan) {
	return cols.map((elem, i) => {
		const copy = { ...elem, properties: { ...elem.properties, rowSpan } };
		return buildPage(copy, i);
	});
}

export default function SplittableDeliveryRow({ onChange, value, quantity, dateProps, splitProps, deleteProps, preCols = [], postCols = [] }) {
	const rowCount = value?.length ?? 0;
	const pre = useMemo(() => addRowSpan(preCols, rowCount), [preCols, rowCount]);
	const post = useMemo(() => addRowSpan(postCols, rowCount), [postCols, rowCount]);
	const update = useCallback((currentValue, index, key, newValue) => {
		const copy = currentValue.slice();
		copy[index] = { ...copy[index], [key]: newValue };
		onChange({ target: { value: copy } });
	}, [onChange]);
	const totalQty = value?.reduce((t, line) => {
		return t + (+line.quantity || 0);
	}, 0) ?? 0;
	const invalid = totalQty !== quantity;
	return <>
		{value?.map((line, i) => {
			return <tr key={i}>
				{i === 0 && pre}
				<td>
					{i === rowCount - 1 && rowCount < quantity && <button {...splitProps} onClick={e => {
						e.preventDefault();
						onChange({ target: { value: value.concat({ quantity: 0, date: null }) } });
					}} />}{' '}
					{rowCount > 1 && <button {...deleteProps} onClick={e => {
						e.preventDefault();
						const copy = value.slice();
						copy.splice(i, 1);
						onChange({ target: { value: copy } });
					}} />}
				</td>
				<td>
					<FormInput
						type="number"
						min="0"
						max={quantity}
						step="1"
						name="quantity"
						invalid={invalid}
						value={line.quantity}
						onChange={e => update(value, i, 'quantity', e.target.value)} />
				</td>
				<td>
					<FormInput {...dateProps} name="date" value={line.date} onChange={e => update(value, i, 'date', e.target.value)} />
				</td>
				{i === 0 && post}
			</tr>;
		})}
	</>;
}
