import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useError } from '../../ErrorBoundary';
import { usePageState } from '../../PageState';
import { callMethod, callRawMethod, getData, postData, submitForm } from '../../../api';
import { fire } from '../../../prompt';

const ApiContext = React.createContext(null);

function applyPageAction(api, action, actions, navigate) {
	if(typeof action.redirect === 'string') {
		if(action.redirect[0] === '#') {
			window.location = action.redirect;
		} else {
			navigate(`/portal/${api}${action.redirect.startsWith('/') ? '' : '/'}${action.redirect}`);
		}
	} else if(typeof action.modify === 'object') {
		const { key, properties, element, children } = action.modify;
		if(properties && !('key' in properties)) {
			properties.key = key;
		}
		actions.modifyPage(key, properties, element, children);
	} else if(Array.isArray(action.multiple)) {
		action.multiple.forEach(a => applyPageAction(api, a, actions, navigate));
	} else if(action.reload) {
		actions.reload(action.reload);
	} else if(typeof action.setFormValue === 'object') {
		const { form, values } = action.setFormValue;
		actions.setFormValue(form, values);
	} else if(action.refresh) {
		window.location.reload();
	} else if(typeof action.prompt === 'object') {
		fire(action.prompt);
	} else if(typeof action.delay === 'object') {
		setTimeout(() => {
			applyPageAction(api, action.delay.action, actions, navigate);
		}, action.delay.ms);
	}
}

function forwardData(api, actions, navigate) {
	return ({ data }) => {
		if(typeof data === 'object' && data['.portal']) {
			applyPageAction(api, data['.portal'].action, actions, navigate);
			return data['.portal'].data;
		}
		return data;
	};
}

function getState(api, actions, setError, navigate) {
	return {
		api,
		callMethod(method, endpoint, context = api, data) {
			setError(null);
			return callMethod(method, endpoint, context, data).then(action => applyPageAction(context, action, actions, navigate)).catch(setError);
		},
		callRawMethod(method, endpoint, context = api, data) {
			return callRawMethod(method, endpoint, context, data);
		},
		getData(endpoint, params, context = api) {
			return getData(context, endpoint, params).then(forwardData(context, actions, navigate)).catch(setError);
		},
		postData(endpoint, params, context = api) {
			return postData(context, endpoint, params).then(forwardData(context, actions, navigate)).catch(setError);
		},
		submitForm(context = api, endpoint, data, useFormData) {
			return submitForm(context, endpoint, data, useFormData).then(action => applyPageAction(context, action, actions, navigate)).catch(setError);
		}
	};
}

export default function ApiState({ api, children }) {
	const setError = useError();
	const { actions } = usePageState();
	const navigate = useNavigate();
	const value = useMemo(() => getState(api, actions, setError, navigate), [api, actions, setError, navigate]);
	return <ApiContext.Provider value={value} children={children} />;
}

export function useApi() {
	return useContext(ApiContext);
}
