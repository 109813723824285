import React, { memo } from 'react';
import { Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import AddressInput from './AddressInput';
import TimeInput from './TimeInput';
import GroupBox from './GroupBox';
import AutoComplete from './AutoComplete';
import RangeInput from './RangeInput';
import SortInput from './SortInput';
import Select from './Select';
import 'react-datepicker/dist/react-datepicker.css';
import CheckBox from './CheckBox';
import IBANInput from './IBANInput';
import FileDrop from './FileDrop';
import VariableDiscount from './VariableDiscount';
import SplittableDeliveryRow from './SplittableDeliveryRow';

function Field(props) {
	if(props.type === 'date') {
		const p = Object.assign({}, props);
		p.className = p.className || 'form-control';
		p.selected = p.value;
		delete p.value;
		if(p.onChange) {
			p.onChange = value => props.onChange({ target: { value } });
		}
		if(p.minDate) {
			p.minDate = new Date(p.minDate);
		}
		if(p.maxDate) {
			p.maxDate = new Date(p.maxDate);
		}
		if(p.placeholder) {
			p.placeholderText = p.placeholder;
			delete p.placeholder;
		}
		return <DatePicker {...p} />;
	} else if(props.type === 'checkbox') {
		const p = Object.assign({}, props);
		p.checked = !!p.value;
		delete p.value;
		if(p.onChange) {
			p.onChange = e => props.onChange({ target: { value: e.target.checked } });
		}
		return <CheckBox {...p} />;
	} else if(props.type === 'radio') {
		const p = Object.assign({}, props);
		p.checked = p.value === p.checked;
		return <input {...p} />;
	} else if(props.type === 'address') {
		return <AddressInput {...props} />;
	} else if(props.type === 'time') {
		return <TimeInput {...props} />;
	} else if(props.type === 'groupbox') {
		return <GroupBox {...props} />;
	} else if(props.type === 'range') {
		return <RangeInput {...props} />;
	} else if(props.type === 'sort') {
		return <SortInput {...props} />;
	} else if(props.type === 'password') {
		const p = Object.assign({}, props);
		if(props.newPassword) {
			p.autoComplete = 'new-password';
		}
		delete p.newPassword;
		props = p;
	} else if(props.type === 'file') {
		const p = Object.assign({}, props);
		delete p.value;
		if(p.onChange) {
			p.onChange = e => props.onChange({ target: { value: e.target.files } });
		}
		return <input {...p} />;
	} else if(props.type === 'filedrop') {
		return <FileDrop {...props} />;
	} else if(props.type === 'iban') {
		return <IBANInput {...props} />;
	} else if(props.type === 'VariableDiscount') {
		return <VariableDiscount {...props} />;
	} else if(props.type === 'SplittableDeliveryRow') {
		return <SplittableDeliveryRow {...props} />;
	} else if(props.autoComplete) {
		return <AutoComplete {...props} />;
	}
	return <Input {...props} />;
}

function MultiInput({ values, onChange, ...props }) {
	let inputs = values;
	if(values.every(Boolean)) {
		inputs = inputs.concat('');
	}
	if(props.type === 'range') {
		props.className = props.className || 'mb-1';
	} else {
		props.className = props.className || 'mb-1 form-control';
	}
	return <>
		{inputs.map((value, key) => {
			const p = { ...props, key, value };
			if(onChange) {
				p.onChange = e => {
					const copy = values.slice();
					copy[key] = e.target.value;
					onChange({ target: { value: copy } });
				};
			}
			return <Field {...p} />;
		})}
	</>;
}

function FormInput(props) {
	const { value, type, multiple } = props;
	if(type === 'select') {
		return <Select {...props} />;
	} else if(multiple && type !== 'radio' && type !== 'file' && type !== 'filedrop') {
		const childProps = Object.assign({}, props);
		childProps.values = value;
		delete childProps.value;
		return <MultiInput {...childProps} />;
	}
	return <Field {...props} />;
}

export default memo(FormInput);

function getDefaultValue(type, value, cb, props) {
	if(type === 'date' && value) {
		return cb(new Date(value));
	} else if(type === 'checkbox') {
		return cb(!!value);
	} else if(type === 'radio') {
		if(value === props.checked) {
			return cb(value);
		}
		return false;
	} else if(type === 'address') {
		return cb(typeof value === 'object' ? value : {});
	} else if(type === 'sort') {
		return cb(value || undefined);
	} else if(type === 'select' && typeof value !== 'object' && Array.isArray(props.values)) {
		value = props.values.find(v => v.value === value) || value;
	} else if(type === 'SplittableDeliveryRow') {
		return cb([{ quantity: props.quantity, date: props.dateProps.value }, { quantity: 0, date: null }]);
	}
	return cb(value || '');
}

export function defaultValue(props, cb) {
	if(props.multiple && props.type !== 'radio') {
		if(Array.isArray(props.value)) {
			return cb(props.value.map(value => getDefaultValue(props.type, value, v => v, props)));
		}
		return cb([]);
	}
	return getDefaultValue(props.type, props.value, cb, props);
}

export function valueKey(props) {
	if(props.type === 'radio') {
		return 'checked';
	}
	return 'value';
}
