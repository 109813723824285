import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import FormInput from './FormInput';
import { useLocale } from '../../UserState';
import Cache from '../../../cache';

const DAYS = 7;
const OPTIONS = { weekday: 'long' };
const DEFAULT = {
	min: '09:00',
	max: '17:00'
};
const SLICES = 2;

const cache = new Cache(([locale]) => {
	if(!window.Intl || !Intl.DateTimeFormat) {
		return {
			format(date) {
				return `${date.getDay()}`;
			}
		};
	}
	return new Intl.DateTimeFormat(locale, OPTIONS);
});

function Input(props) {
	const pass = Object.assign({}, props);
	delete pass.showLabels;
	return <FormGroup>
		{props.showLabels && props.placeholder ? <label className="small font-weight-boldmuted">{props.placeholder}</label> : null}
		<FormInput {...pass} />
	</FormGroup>;
}

function repeat(times, cb) {
	return Array(times).fill().map((_, i) => cb(i));
}

function getLabel(option) {
	if(!option) {
		return '';
	}
	return `${option.addresses?.[0] ?? ''} ${option.city ?? ''}`.trim() || option.names?.[0] || '';
}

function useOptions(values, value, onChange) {
	const [state, setState] = useState({ options: [], option: -1 });
	const select = e => {
		setState(prev => ({ ...prev, option: state.options.findIndex(o => o.value === e.target.value.value) }));
		onChange({ target: { value: e.target.value.address } });
	};
	useEffect(() => {
		if(values) {
			let index = -1;
			const options = values.map((option, i) => {
				if(index < 0 && value && value._id === option._id) {
					index = i;
				}
				return {
					value: option._id,
					label: getLabel(option),
					address: option
				};
			});
			if(index < 0) {
				const address = value || {
					_id: null
				};
				options.unshift({
					value: address._id,
					label: getLabel(value),
					address
				});
				index = 0;
			}
			setState({ options, option: index });
			onChange({ target: { value: options[index].address } });
		}
	}, [values]);
	const changeValue = (name) => {
		return e => {
			const address = { ...value, [name]: e.target.value };
			const options = state.options.slice();
			options[state.option] = { ...state.option, address, label: getLabel(address) };
			setState( prev => ({ ...prev, options }));
			onChange({ target: { value: address } });
		};
	};
	const changeMultiValue = (name, i) => {
		const change = changeValue(name);
		return e => {
			const current = value[name];
			let copy;
			if(current && current.length) {
				copy = current.slice();
			} else {
				copy = [];
			}
			copy[i] = e.target.value;
			change({ target: { value: copy } });
		};
	};
	return { ...state, select, changeValue, changeMultiValue };
}

function OpeningHours({ locale, value, placeholder, hours, changeValue }) {
	if(hours === false) {
		return null;
	}
	const change = changeValue('open');
	const changeClosed = dayIndex => {
		return e => {
			const open = (value.open || []).slice();
			while(open.length < DAYS) {
				open.push([]);
			}
			if(e.target.value) {
				open[dayIndex] = [];
			} else {
				open[dayIndex] = [DEFAULT];
			}
			change({ target: { value: open } });
		};
	};
	const changeDateValue = (dayIndex, sliceIndex, type) => {
		return e => {
			const open = (value.open || []).slice();
			while(open.length < DAYS) {
				open.push([]);
			}
			const day = open[dayIndex].slice();
			open[dayIndex] = day;
			if(e.target.value) {
				day[sliceIndex] = { ...day[sliceIndex], [type]: e.target.value };
			} else {
				day.splice(sliceIndex, 1);
			}
			change({ target: { value: open } });
		};
	};
	const formatter = cache.get(locale);
	const date = new Date('2019-06-16');
	const days = [
		<span key="hours" className="title-2">Hours</span>,
		<Row key="header" className='bg-sub color-dark'>
			<Col>{placeholder?.day ?? ''}</Col>
			<Col>{placeholder?.closed ?? ''}</Col>
			{repeat(SLICES, key => <Fragment key={key}>
				<Col>{placeholder?.minTime ?? ''}</Col>
				<Col>{placeholder?.maxTime ?? ''}</Col>
			</Fragment>)}
		</Row>
	];
	const open = value.open || [];
	for(let i = 0; i < DAYS; i++) {
		const slices = open[i];
		const closed = !slices?.length;
		days.push(<Row key={i}>
			<Col>
				<strong>{formatter.format(date)}</strong>
			</Col>
			<Col>
				<FormGroup className="mb-0">
					<FormInput type="checkbox" value={closed} onChange={changeClosed(i)} />
				</FormGroup>
			</Col>
			{repeat(SLICES, key => <Fragment key={key}>
				<Col>
					{!closed ? <FormGroup className="mb-0">
						<FormInput type="time" value={slices[key]?.min ?? ''} onChange={changeDateValue(i, key, 'min')} allowEmpty />
					</FormGroup> : 'Closed'}
				</Col>
				<Col>
					{!closed ? <FormGroup className="mb-0">
						<FormInput type="time" value={slices[key]?.max ?? ''} onChange={changeDateValue(i, key, 'max')} allowEmpty />
					</FormGroup> : 'Closed'}
				</Col>
			</Fragment>)}
		</Row>);
		date.setDate(date.getDate() + 1);
	}
	return days;
}

export default function AddressInput({ onChange, value, values, placeholder, hours, names: propNames, showLabels, showMobile = false, hide = {}, hideDropdown, numNames = 1, numAddresses = 1, countryProps, className = 'address-input mt-4', ...props }) {
	const locale = useLocale();
	delete props.type;
	delete props.name;
	const { options, option, select, changeValue, changeMultiValue } = useOptions(values, value, onChange);
	if(option < 0 && !hideDropdown || !value) {
		return null;
	}
	const { phone, email, country, addresses, city, postalCode, names, mobile } = value;
	const passCountry = {
		type: 'text',
		onChange: changeValue('country'),
		value: country ?? '',
		placeholder: placeholder?.country,
		showLabels: showLabels,
		...countryProps
	};
	return <div {...props} className={className}>
		<span className="title-2">Address</span>
		{!hideDropdown && <FormGroup className="mt-2">
			{/* added col for equal margin */}
			<Row>
				<Col>
					<FormInput type="select" values={options} onChange={select} value={options[option]} />
				</Col>
			</Row>
		</FormGroup>}
		<Row className="address-input-contact mt-2">
			{propNames ? repeat(numNames, key => <Col md={6} key={key}>
				<Input type="text" onChange={changeMultiValue('names', key)} value={names?.[key] ?? ''} placeholder={placeholder?.name} showLabels={showLabels} maxLength={50} />
			</Col>) : null}
			{!hide.phone && <Col md={6}>
				<Input type="tel" onChange={changeValue('phone')} value={phone ?? ''} placeholder={placeholder?.phone} showLabels={showLabels} maxLength={30} pattern="[0-9]*" />
			</Col>}
			{showMobile && <Col md={6}>
				<Input type="tel" onChange={changeValue('mobile')} value={mobile ?? ''} placeholder={placeholder?.mobile} showLabels={showLabels} maxLength={30} pattern="[0-9]*" />
			</Col>}
			{!hide.email && <Col md={6}>
				<Input type="email" onChange={changeValue('email')} value={email ?? ''} placeholder={placeholder?.email} showLabels={showLabels} />
			</Col>}
			{repeat(numAddresses, key => <Col md={6} key={key}>
				<Input type="text" onChange={changeMultiValue('addresses', key)} value={addresses?.[key] ?? ''} placeholder={placeholder?.address} showLabels={showLabels} maxLength={50} />
			</Col>)}
			{!hide.postalCode && <Col md={6}>
				<Input type="text" onChange={changeValue('postalCode')} value={postalCode ?? ''} placeholder={placeholder?.postalCode} showLabels={showLabels} />
			</Col>}
			{!hide.city && <Col md={6}>
				<Input type="text" onChange={changeValue('city')} value={city ?? ''} placeholder={placeholder?.city} showLabels={showLabels} maxLength={30} />
			</Col>}
			{!hide.country && <Col md={6}>
				<Input {...passCountry} />
			</Col>}
		</Row>
		<div className="address-input-hours mt-3">
			<OpeningHours locale={locale} value={value} placeholder={placeholder} hours={hours} changeValue={changeValue} />
		</div>
	</div>;
}
